<template>
    <div class="home">
      <div class="pt20"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="订单号">
             <el-input v-model="formInline.order_no" placeholder="订单号"></el-input>
            </el-form-item>
            <el-form-item label="付款状态">
              <el-select v-model="formInline.pay_status" placeholder="请选择付款状态">
                <el-option label="已过期" :value="-1"></el-option>
                <el-option label="待支付" :value="0"></el-option>
                <el-option label="支付成功" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 

      <div class="pt20"></div>
      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%" >
      <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column
          prop="order_no"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="price"
          label="价格">
        </el-table-column>
        <el-table-column
          prop="buy_type"
          label="购买期限">
          <template slot-scope="scope">
            {{scope.row.buy_type == 0 ? '永 久' : ''}}
            {{scope.row.buy_type == 1 ? '1个月' : ''}}
            {{scope.row.buy_type == 3 ? '3个月' : ''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="describe"
          label="描述">
        </el-table-column>
        <el-table-column
          prop="pay_type"
          label="支付方式">
        </el-table-column>
        <el-table-column
          prop="head_img"
          label="头像图片地址">
          <template slot-scope="scope">
            <el-image
            v-if="scope.row.head_img"
            style="width: 88px; height: 88px"
            :src="scope.row.head_img"></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="en_head_name"
          label="头像英文名">
        </el-table-column>
        <el-table-column
          prop="head_name"
          label="头像中文名">
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="订单创建时间">
        </el-table-column>
        <el-table-column
          prop="updated_at"
          label="订单支付时间">
        </el-table-column>
        <el-table-column
          prop="user.nickname"
          label="用户昵称">
        </el-table-column>
        <el-table-column
          prop="user.id"
          label="用户手机号">
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
        order_no:'',
        pay_status:1,
        page:1,
      },
      initdata:{
        order_no:'',
        pay_status:1,
        page:1,
      },
      timevalue:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        axios.get(config.headOrder,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
           _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.headOrder,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
    text-align: left;
}
</style>